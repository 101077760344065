export {}
;(function () {
  function initializeExport() {
    const button = document.getElementById('export-menu-button') as HTMLButtonElement
    const menu = document.getElementById('export-menu') as HTMLDivElement
    const exportLink = document.getElementById('export-xlsx-link') as HTMLAnchorElement
    const loadingIndicator = document.getElementById('export-loading') as HTMLDivElement
    const exportError = document.getElementById('export-error') as HTMLDivElement

    if (!button || !menu || !exportLink || !loadingIndicator || !exportError) {
      console.error('One or more export elements not found')
      return
    }

    button.addEventListener('click', function () {
      menu.classList.toggle('hidden')
    })

    document.addEventListener('click', function (event) {
      if (!button.contains(event.target as Node) && !menu.contains(event.target as Node)) {
        menu.classList.add('hidden')
      }
    })

    function performExport(url: string) {
      loadingIndicator.classList.remove('hidden')
      exportError.classList.add('hidden')

      fetch(url, {
        method: 'GET',
        headers: {
          Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        },
      })
        .then((response) => {
          if (response.ok) {
            return response.blob()
          } else {
            throw new Error(`Unexpected response status: ${response.status}`)
          }
        })
        .then((blob) => {
          handleSuccessfulExport(blob)
        })
        .catch((error) => {
          console.error('Export failed:', error)
          handleExportError(new Error('Export failed. Please try again later.'))
        })
        .finally(() => {
          loadingIndicator.classList.add('hidden')
        })
    }

    exportLink.addEventListener('click', function (this: HTMLAnchorElement, event: MouseEvent) {
      event.preventDefault()
      performExport(this.href)
    })

    function handleSuccessfulExport(blob: Blob) {
      const url = window.URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.style.display = 'none'
      a.href = url
      a.download = `Export YSolutions - Transparenzregisteraufträge_${new Date().toISOString().slice(0, 19).replace(/[-:]/g, '')}.xlsx`
      document.body.appendChild(a)
      a.click()
      window.URL.revokeObjectURL(url)
    }

    function handleExportError(error: Error) {
      console.error('Export error:', error)
      exportError.classList.remove('hidden')
      exportError.textContent = error.message
    }
  }

  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', initializeExport)
  } else {
    initializeExport()
  }
})()
